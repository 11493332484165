import React from 'react'
import PropTypes from "prop-types"

import ResponsiveImage from '../responsiveImage/responsiveImage'
 
const ImageSection = ( props ) => {
  if (props.section.primary.image === null) {
    return null
  }

  const image = props.section.primary.image

  return (
    <div className={`section-slim-seamless`}>
      <div className="cols-wrapper">
        <div className="cols">
          <div className={`col-8-md push-4-md col-6-lg push-5-lg`}>
            <ResponsiveImage
              src={image.url}
              width={image.dimensions.width}
              height={image.dimensions.height}
              alt={image.alt}
              widths={['2200', '1500', '750']}
              parentFit="width"
              classes="image-block"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ImageSection.propTypes = {
  section: PropTypes.object
}

ImageSection.defaultProps = {
  section: {}
}
 
export default ImageSection