import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"
 
import { linkResolver } from '../../utils/linkResolver'

const SideTextSection = ( props ) => {
  if (props.section.primary.section_title === null && props.section.primary.section_text === null) {
    return null
  }

  return (
    <div className={`section-slim-seamless`} id={props.id}>
      <div className="cols-wrapper">
        <div className="cols">
          <div className={`col-12-md col-12-lg`}>
            <div className="cols-wrapper">
              <div className="cols">
                <div className="col-4-md col-4-lg">
                  {!!props.section.primary.section_title &&
                    <h2 className={`t-h3 t-semi t-color-blue section-title`}>{RichText.asText(props.section.primary.section_title)}</h2>
                  }
                </div>
                {!!props.section.primary.section_text &&
                  <div className="col-8-md col-6-lg push-1-lg">
                    <div className={`t-body text-body`}>
                      <RichText
                        render={props.section.primary.section_text}
                        linkResolver={linkResolver}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

SideTextSection.propTypes = {
  section: PropTypes.object
}

SideTextSection.defaultProps = {
  section: {}
}
 
export default SideTextSection