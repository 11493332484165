import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"
import { renderBackground } from '../../utils/renderBackground'

import styles from './pageHeaderSlim.module.scss'
 
const PageHeader = ( props ) => (
  <div className={`${styles.header} ${props.background ? styles.headerWithBackground : ''}`}>
    <div className={`${styles.wrapper} cols-wrapper`}>
      <div className="cols">
        <div className="col-12-md col-12-lg">
          <h1 className={`t-h1 ${!props.background && props.color}`}>
            {typeof props.title === 'string' ? props.title : RichText.asText(props.title)}
          </h1>
        </div>
      </div>
    </div>
    {props.background &&
      <div className={styles.background}>
        {renderBackground(props.background)}
      </div>
    }
  </div>
)

PageHeader.propTypes = {
  title: PropTypes.array,
  color: PropTypes.string
}

PageHeader.defaultProps = {
  title: {},
  color: 't-color-blue'
}
 
export default PageHeader