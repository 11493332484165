import React from "react"
import { RichText } from "prismic-reactjs"
import { graphql } from "gatsby"

import SEO from "../components/seo"

import PageHeaderSlim from '../components/pageHeaderSlim/pageHeaderSlim'
import SideTextSection from '../components/sideTextSection/sideTextSection'
import ImageSection from '../components/imageSection/imageSection'
  
export const query = graphql`
  query Policies($uid: String) {
    prismic {
      allPoliciess(uid: $uid) {
        edges {
          node {
            title
            body {
              ... on PRISMIC_PoliciesBodyText_section {
                type
                label
                primary {
                  section_text
                  section_title
                }
              }
              ... on PRISMIC_PoliciesBodyImage {
                type
                label
                primary {
                  image
                }
              }
            }
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

const Policies = ({ data }) => {
  const doc = data.prismic.allPoliciess.edges.slice(0,1).pop();
  if (!doc) return null;

  return (
    <React.Fragment>
      <SEO title={RichText.asText(doc.node.title)} />
      <PageHeaderSlim title={doc.node.title} />
      <div style={{
        overflow: "auto"
      }}>
        {doc.node.body !== null ? doc.node.body.map((section, index) => {
          switch (section.type) {
            case 'text_section':
              return (
                <SideTextSection section={section} />
              )
            case 'image':
              return (
                <ImageSection section={section} />
              )
            default:
              return null
          }
        }) : null}
      </div>
    </React.Fragment>
  )
}

export default Policies
